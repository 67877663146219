import React from 'react';
import { motion } from "framer-motion";
import { SendAlt24, Close24 } from '@carbon/icons-react';
import { DESCRIPTIONS } from './Descriptions';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

export function Description(props) {
    return (
      <motion.div
        className="description card"
        initial={{y: 40, opacity: 0}}
        animate={{y: 0, opacity: 1}}
        transition={{ease: 'easeInOut', delay: props.startDelay + 0.05 * props.delay, x: { type: "tween", stiffness: 100 }, default: { duration: 0.4 }}}>
        <h2>{props.title}</h2>
        <p>{props.desc}</p>
      </motion.div>
    );
}

export const STYLE = {
    show: {filter: `blur(4px)`, opacity: 0.2, transitionTimingFunction: 'ease-in-out', transitionDuration: '0.3s'},
    hide: {filter: `blur(0px)`, opacity: 1, transitionTimingFunction: 'ease-in-out', transitionDuration: '0.3s'}
}

function Main() {
    const [showMail, setShowMail] = React.useState(false);
    const handleShowMail = () => {
        setShowMail(prevState => !prevState);
    }

    const mailVariants = {
        open: {x: 0, opacity: 1, visibility: 'visible'},
        closed: {x: 40, opacity: 0, visibility: 'hidden'}
    }

    const delay = 0.1;

    const trackClickHelp = (e) => {
        e.preventDefault();
        handleShowMail();
        trackCustomEvent({
            category: "How can I help",
            action: "Click",
            label: "CTA Home",
        });
    }
    const trackClickSendEmail = (e) => {
        trackCustomEvent({
            category: "Send e-mail",
            action: "Click",
            label: "CTA Home",
        });
    }

    return (
        <main>
          <div class="hero">
            <div style={showMail ? STYLE.show : STYLE.hide}>
                <motion.span className="subheader" transition={{ease: 'easeInOut', delay: delay + 0.5, duration: 0.4}} initial={{x: 100, opacity: 0}} animate={{x: 0, opacity: 1}}>
                    Hello there
                </motion.span>
                <motion.h1 transition={{ease: 'easeInOut', delay: delay + 0.15, duration: 0.25}} initial={{y: -40, opacity: 0}} animate={{y: 0, opacity: 1}}>
                    I am Łukasz, a web developer
                </motion.h1>
                <motion.p class="subtitle" transition={{ease: 'easeInOut', delay: delay, duration: 0.4}} initial={{y: -40, opacity: 0}} animate={{y: 0, opacity: 1}}>
                    Great things happen when we work together.
                </motion.p>
            </div>
            <div class="cta">
                <motion.button onClick={trackClickHelp} className={showMail && "active"} transition={{ease: 'easeInOut', delay: delay + 0.15, duration: 0.4}} initial={{y: -10, opacity: 0}} animate={{y: 0, opacity: 1}}>How can I help?</motion.button>
                <motion.a onClick={trackClickSendEmail} transition={{ease: 'easeInOut', duration: 0.4}} variants={mailVariants} initial={mailVariants.closed} animate={showMail ? "open" : "closed"} href="mailto:contact@kamrowsky.pl?subject=My great idea" className="btn"><SendAlt24 />send e-mail</motion.a>
                <motion.span transition={{delay:0.05, ease: 'easeInOut', duration: 0.35}} variants={mailVariants} initial={mailVariants.closed} animate={showMail ? "open" : "closed"} className="caption">What's your <span className="colored-brand">great idea?</span></motion.span>
                <motion.span onClick={handleShowMail} transition={{delay:0.05, ease: 'easeInOut', duration: 0.35}} variants={mailVariants} initial={mailVariants.closed} animate={showMail ? "open" : "closed"} className="caption cta-close"><Close24 />Close</motion.span>
            </div>
          </div>
          <section className="grid" style={showMail ? STYLE.show : STYLE.hide}>
            {DESCRIPTIONS.map((item, index) => <Description {...item} startDelay={delay + 0.5} delay={index} key={index}/>)}
          </section>
        </main> 
    )
}

export default Main;

import React from 'react';
import logoIcon from '../images/logo-icon-white.svg';
import logoText from '../images/logo-text-white.svg';
import { motion } from "framer-motion";

function Logo() {
    return (
        <motion.div className="logoWrapper" transition={{ease: 'easeInOut', delay: 1, duration: 0.4}} initial={{y: '-200%', opacity: 0}} animate={{y: 0, opacity: 1}}>
            <motion.img animate={{ rotate: 180 }} transition={{ delay: 3, repeat: Infinity, repeatDelay: 6, repeatType: 'reverse', duration: 0.5 }} src={logoIcon} className="logoIcon" alt="Logo"/>
            <img src={logoText} className="logoText" alt="Logo"/>
        </motion.div>
    );
}

function Header() {
    return (
        <header>
            <Logo/>
        </header>
    )
}

export default Header;

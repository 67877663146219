import React from "react";
import './index.scss';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Main from '../components/Main';
import { motion } from "framer-motion";
import {Helmet} from "react-helmet";

export default function Home() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Łukasz Kamrowski - Web Developer, ReactJS, NodeJS, WordPress</title>
        <link rel="canonical" href="http://kamrowsky.pl" />
      </Helmet>
      <div id="site">
        <div id="site-header">
          <Header/>
        </div>
        <div id="site-main">
          <Main/>
        </div>
        <motion.div id="site-footer" transition={{ease: 'easeInOut', delay: 1, duration: 0.4}} initial={{opacity: 0}} animate={{opacity: 1}}>
          <Footer/>
        </motion.div>
      </div>
    </>
  );
}

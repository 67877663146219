import React from 'react';

export const DESCRIPTIONS = [
    {
      title: 'the process',
      desc: <span>I take care of every step of the process:
          <ul><li>Researching,</li><li>Designing,</li><li>Developing your product</li></ul></span>
    },
    {
      title: 'technology Stack',
      desc: <span>My currently go-to front-end framework is <a target="_new" href="https://reactjs.org/">ReactJS</a>
      &nbsp;powered by <a target="_new" href="https://www.typescriptlang.org/">Typescript</a>. This duo allows me to create modern web experience for users.
      <p>To develop a back-end I use <a target="_new" href="https://expressjs.com/">NodeJS</a> & <a target="_new" href="https://expressjs.com/">ExpressJS</a>, <a href="https://www.python.org/">Python</a> and <a href="https://www.php.net/">PHP</a>.</p></span>
    },
    {
      title: `what's up`,
      desc: <span>Currently, I am a part of <a target="_new" href="https://sanasens.com/">Sanasens</a>, a company developing AI powered hive-monitoring system <a target="_new" href="https://smartula.eu/">Smartula</a>
      <p>I am also learning & expanding my tech stack: Contentful, GatsbyJS, Django</p></span>
    }
];
import React from 'react';

function Footer() {
    const year = new Date().getFullYear();

    return (
        <footer>
          <span class="caption">{`${year} © Łukasz Kamrowski`}</span>
        </footer>
    )
}

export default Footer;
